import { useState } from "react";
import firebase from "firebase/compat/app";
import { Box, Button, Dialog, Divider, FormControl, Icon, IconButton, Input, InputAdornment, InputLabel, ListItemIcon, Menu, MenuItem, Paper, TextField, Tooltip, Typography } from "@mui/material";
import DefaultAccount from "../../assets/DefaultAccount.png";
import { AccountCircle, AddCircleOutline, ArrowBackIos, Close, Google, Logout, Send, Visibility, VisibilityOff } from "@mui/icons-material";
import "./AuthInterface.css";


interface AuthInterfaceProps {
    auth: any,
    createAlert: (severity: string, message: string) => void
}

const AuthInterface = ({ auth, createAlert }: AuthInterfaceProps) => {

    const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null);
    const [authDialogOpen, setAuthDialogOpen] = useState(false);
    const [displayName, setDisplayName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loadingEmailAuth, setLoadingEmailAuth] = useState(false);
    const [loadingCreateAccount, setLoadingCreateAccount] = useState(false);
    const [loadingSendRecoveryEmail, setLoadingSendRecoveryEmail] = useState(false);

    const [showLoginUI, setShowLoginUI] = useState(true);
    const [showCreateAccountUI, setShowCreateAccountUI] = useState(false);
    const [showForgotPasswordUI, setShowForgotPasswordUI] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => { setShowPassword((show) => !show) }

    const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
    };

    const signInWithGoogle = async () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({});

        try {
            await auth.signInWithPopup(provider);
            createAlert("success", "Signed in successfully");
        } catch (error) {
            createAlert("error", "Error signing in");
        }
    }

    const makeFirebaseAuthRespHumanReadable = (error: any) => {
        let message = "";
        switch (error.code) {
            case "auth/invalid-login-credentials":
                message = "Invalid login credentials";
                break;

            default:
                message = error.message;
                break;
        }

        return message;
    }

    const signInWithEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoadingEmailAuth(true);

        try {
            const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
            const user = userCredential.user;
            createAlert("success", "Signed in successfully");
            setLoadingEmailAuth(false);
            closeDialog();
        } catch (error) {
            createAlert("error", makeFirebaseAuthRespHumanReadable(error));
            setLoadingEmailAuth(false);
        }
    }

    const createAccountWithEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoadingCreateAccount(true);

        try {
            const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
            const user = userCredential.user;
            await user?.updateProfile({
                displayName: displayName
            });
            await firebase.firestore().collection("Users").doc(user?.uid).update({ fbDisplayName: displayName });

            createAlert("success", "Account created successfully");
            setLoadingCreateAccount(false);
            closeDialog();
        } catch (error) {
            console.error(error);
            createAlert("error", makeFirebaseAuthRespHumanReadable(error));
            setLoadingCreateAccount(false);
        }
    }

    const submitForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoadingSendRecoveryEmail(true);

        try {
            await firebase.auth().sendPasswordResetEmail(email);
            createAlert("success", "Recovery email sent successfully");
            setLoadingSendRecoveryEmail(false);
            hideForgotPassword();
        } catch (error) {
            createAlert("error", makeFirebaseAuthRespHumanReadable(error));
            setLoadingSendRecoveryEmail(false);
        }
    }

    const clearForm = () => {
        setEmail("");
        setPassword("");
    }

    const openDialog = () => {
        setAuthDialogOpen(true);
    }

    const closeDialog = () => {
        clearForm();
        hideCreateAccount();
        setAuthDialogOpen(false);
    }

    const showCreateAccount = () => {
        setShowCreateAccountUI(true);
        setShowLoginUI(false);
    }

    const hideCreateAccount = () => {
        setShowCreateAccountUI(false);
        setShowLoginUI(true);
    }

    const showForgotPassword = () => {
        setShowForgotPasswordUI(true);
        setShowLoginUI(false);
    }

    const hideForgotPassword = () => {
        setShowForgotPasswordUI(false);
        setShowLoginUI(true);
    }

    const signOut = async () => {
        closeDialog();
        try {
            await auth.signOut();
            createAlert("success", "Signed out successfully");
        } catch (error) {
            createAlert("error", "Error signing out");
        }
    }

    return (
        auth.currentUser ? (
            <Box style={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <Tooltip title={undefined}>
                    <img
                        src={auth.currentUser.photoURL ? auth.currentUser.photoURL : DefaultAccount}
                        className="profileImg"
                        alt={auth.currentUser.fbDisplayName}
                        onClick={(e) => { setAnchorEl(e.currentTarget)}}
                        aria-controls={Boolean(anchorEl) ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                    />
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    onClick={() => setAnchorEl(null)}
                    PaperProps={{
                        elevation: 0,
                        style: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            // @ts-ignore
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                m1: -0.5,
                                mr: 1
                            },
                            "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0
                            }
                        }
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    <MenuItem
                        onClick={() => signOut()}
                    >
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
            </Box>
        ) : (
            <>
                <Button
                    color="inherit"
                    onClick={openDialog}
                >
                    <AccountCircle fontSize="small" style={{marginRight: '10px'}}/>
                    Sign In
                </Button>
                <Dialog
                    onClose={closeDialog}
                    open={authDialogOpen}
                >
                    <Paper
                        className="authInterfaceLoginHolder"
                        elevation={5}
                    >
                        <IconButton
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px"
                            }}
                            onClick={closeDialog}
                        >
                            <Close />
                        </IconButton>
                        <Box
                            className="authInterfaceLogin"
                            style={{ display: showLoginUI ? "flex" : "none", flex: 1 }}
                        >
                            <div style={{ flex: 2 }}></div>

                            <Typography
                                variant="h4"
                                style={{
                                    marginBottom: "20px",
                                    fontWeight: "500"
                                }}
                            >
                                Sign in
                            </Typography>

                            <div style={{ flex: 1 }}></div>
                            
                            <form onSubmit={signInWithEmail}>
                                <TextField
                                    label="Email"
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    name="email"
                                    autoComplete="email"
                                    size="small"
                                    fullWidth
                                    style={{ marginBottom: "15px" }}
                                    variant="standard"
                                    onFocus={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        window.scrollTo(0,0);
                                    }}
                                />

                                <FormControl
                                    // @ts-ignore
                                    variant="contained"
                                    fullWidth
                                    style={{ marginBottom: "20px" }}
                                    onFocus={(e: React.FocusEvent<HTMLElement>) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        window.scrollTo(0,0);
                                    }}
                                >
                                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                    
                                    <Input
                                        id="standard-adornment-password"
                                        // @ts-ignore
                                        variant="contained"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type={showPassword ? "text" : "password"}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }  
                                    />
                                </FormControl>

                                <div className="authInterfaceLoginEmailActionHolder">
                                    <Button
                                        color="primary"
                                        variant="text"
                                        size="small"
                                        onClick={showForgotPassword}
                                    >
                                        Forgot password?
                                    </Button>

                                    <Button
                                        color="primary"
                                        variant="text"
                                        size="small"
                                        onClick={showCreateAccount}
                                    >
                                        Create account
                                    </Button>
                                </div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{ marginBottom: "10px" }}
                                    fullWidth
                                    startIcon={<AccountCircle />}
                                    disabled={loadingEmailAuth}
                                >
                                    Sign In
                                </Button>
                            </form>

                            <Divider style={{ margin: "20px 0", color: "#757575" }}>Or</Divider>

                            <Button
                                variant="contained"
                                // @ts-ignore
                                color="google"
                                onClick={signInWithGoogle}
                                startIcon={<Google />}
                            >
                                Sign in with Google
                            </Button>
                        </Box>

                        <Box
                            className="authInterfaceCreateAccount"
                            style={{ display: showCreateAccountUI ? "flex" : "none", flex: 1}}
                        >
                            <div style={{ flex: 2 }}></div>

                            <Typography
                                variant="h4"
                                style={{ marginBottom: "20px", fontWeight: "500" }}
                            >
                                Create Account
                            </Typography>

                            <div style={{ flex: 1 }}></div>

                            <form onSubmit={createAccountWithEmail}>
                                <TextField
                                    label="Display Name"
                                    type="text"
                                    id="displayName"
                                    value={displayName}
                                    onChange={(e) => setDisplayName(e.target.value)}
                                    name="displayName"
                                    autoComplete="displayName"
                                    size="small"
                                    fullWidth
                                    style={{ marginBottom: "15px" }}
                                    onFocus={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        window.scrollTo(0,0);
                                    }}
                                    variant="standard"
                                />

                                <TextField
                                    label="Email"
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    name="email"
                                    autoComplete="email"
                                    size="small"
                                    fullWidth
                                    style={{ marginBottom: "15px" }}
                                    variant="standard"
                                    onFocus={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        window.scrollTo(0,0);
                                    }}
                                />

                                <FormControl
                                    // @ts-ignore
                                    variant="contained"
                                    fullWidth
                                    style={{ marginBottom: "20px" }}
                                    onFocus={(e: React.FocusEvent<HTMLElement>) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        window.scrollTo(0,0);
                                    }}
                                >
                                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>

                                    <Input
                                        id="sandard-adornment-password"
                                        // @ts-ignore
                                        variant="contained"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type={showPassword ? "text" : "password"}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{ marginBottom: "10px" }}
                                    fullWidth
                                    startIcon={<AddCircleOutline />}
                                    disabled={loadingCreateAccount}
                                >
                                    Create Account
                                </Button>
                            </form>

                            <Divider style={{ margin: "20px 0", color: "#757575" }}>Already have an account?</Divider>

                            <Button
                                variant="text"
                                color="primary"
                                onClick={hideCreateAccount}
                                startIcon={<ArrowBackIos />}
                            >
                                Back to sign in
                            </Button>
                        </Box>

                        <Box
                            className="authInterfaceCreateAccount"
                            style={{ display: showForgotPasswordUI ? "flex" : "none", flex: 1}}
                        >
                            <div style={{ flex: 2 }}></div>

                            <Typography
                                variant="h4"
                                style={{ marginBottom: "20px", fontWeight: "500" }}
                            >
                                Forgot Password
                            </Typography>

                            <Typography
                                // @ts-ignore
                                variant="p"
                                style={{ marginBottom: "20px", fontWeight: "100" }}
                            >
                                Send an account recover link to your email.
                            </Typography>

                            <div style={{ flex: 1 }}></div>

                            <form onSubmit={submitForgotPassword}>
                                <TextField
                                    label="Email"
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    name="email"
                                    autoComplete="email"
                                    size="small"
                                    fullWidth
                                    style={{ marginBottom: "15px" }}
                                    variant="standard"
                                    onFocus={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        window.scrollTo(0,0);
                                    }}
                                />

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{ marginBottom: "10px"}}
                                    fullWidth
                                    startIcon={<Send />}
                                    disabled={loadingSendRecoveryEmail}
                                >
                                    Send Recovery Email
                                </Button>
                            </form>

                            <Divider style={{ margin: "20px 0", color: "#757575" }}>Already have an account?</Divider>

                            <Button
                                variant="text"
                                color="primary"
                                onClick={hideForgotPassword}
                                startIcon={<ArrowBackIos />}
                            >
                                Back to sign in
                            </Button>
                        </Box>
                    </Paper>
                </Dialog>
            </>
        )
    );
}

export default AuthInterface;
