import { createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';


const primaryTheme = createTheme({
    palette: {
      primary: {
        main: "#212121",
        // @ts-ignore    
        font: "#212121"
      },
      secondary: {
        main: "#f9a825"
      },
      naturalChange: {        
        main: "#00897b"
      },
      landclearing: {
        main: "#03A9F4"
      },    
      newConstruction: {
        main: "#f9a825"
      },
      renovation: {
        main: "#9c27b0"
      }, 
      falsePositive: {
        main: "#212121",
        contrastText: "#ffffff" //button text white instead of black
      },
      white: {
        main: "#ffffff",
        font: "#ffffff"
      },
      black: {
        main: "#212121",
        font: "#212121"
      },
      red: {
        main: "#f44336"
      },
      green: {
        main: '#4CAF50'
      },
      lot: {
        main: "#4CAF50"
      },
      gray: {
        main: "#757575"
      },
      disabled: {
        main: "#BDBDBD"
      },
      google: {
        main: "#E34133",
        font: '#ffffff',
        contrastText: "#ffffff",
      },

  

    },
    components: {
      MuiSlider: {
        styleOverrides: {
          mark: {
            backgroundColor: '#ffffff', 
            width: '4px',
            height: '4px',
            borderRadius: '50%',
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: '#f9a825'
          },
        },
      },
    },
  
  });
  
const darkTheme = createTheme({
    palette: {
      primary: {
        main: orange[500],
      },
    },
  });
  


export { darkTheme, primaryTheme }