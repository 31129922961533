import { useState } from "react";
import { Explicit, PlayArrow } from "@mui/icons-material";
import { Button, Fab, Badge, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";

import { SortableList } from "../SortableList/SortableList";
import MergedSongRequest from "../../models/MergedSongRequest";


interface QueueViewProps {
    songQueue: string[],
    songs: MergedSongRequest[],
    updateQueue: (_newQueue: string[], _successMessage: string, _errorMessage: string) => Promise<void>,
    markSongAsPlayed: (_song: MergedSongRequest) => Promise<void>,
    setViewSongObj: React.Dispatch<React.SetStateAction<MergedSongRequest | null>>
}

const QueueView = ({ songQueue, songs, updateQueue, markSongAsPlayed, setViewSongObj }: QueueViewProps) => {

    const [markedSong, setMarkedSong] = useState<MergedSongRequest | null>(null);

    let songQueueObject = songQueue.map((songId, index) => ({ id: songId }));


    return (
        <div>
            <SortableList
                items={songQueueObject}
                onChange={(items: { id: string}[]) => {
                    updateQueue(items.map((item) => item.id), "Queue Updated", "Error updating queue");
                }}
                renderItem={(item: { id: string }) => {
                    const song = songs.find((song) => song.id === item.id);

                    if (song === null || song === undefined) {
                        return null;
                    }

                    const index = songQueueObject.findIndex((song) => song.id === item.id);

                    return (
                        <SortableList.Item id={item.id}>
                            <img
                                className="songPickerRequestImage"
                                src={song?.songData?.thumbnail}
                                alt={song?.songData?.name}
                                onClick={() => {
                                    setViewSongObj(song);
                                }}
                            />

                            <div
                                className="songPickerRequestTextContent"
                                style={{ width: "calc(100vw - 210px)" }}
                                onClick={() => {
                                    setViewSongObj(song);
                                }}
                            >
                                <div
                                    className="songPickerRequestName"
                                    style={{ width: "calc(100vw - 210px)" }}
                                >
                                    {song?.songData?.name}
                                </div>

                                <div
                                    className="songPickerRequestArtists"
                                    style={{ width: "calc(100vw - 210px" }}
                                >
                                    {
                                        song?.songData?.explicit ? (
                                            <Explicit
                                                htmlColor="gray"
                                                style={{
                                                    fontSize: "14px",
                                                    marginRight: "3px"
                                                }}
                                            />
                                        ) : (
                                            null
                                        )
                                    }
                                    {
                                        song?.songData?.artists
                                    }
                                </div>

                                <div
                                    className="songPickerRequestArtists"
                                    style={{ width: "calc(100vw - 210px)" }}
                                >
                                    { index === 0 ? "Song up next" : "" }
                                </div>
                            </div>

                            <div className="queueViewActionArea">
                                <Fab
                                    color="primary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setMarkedSong(song);
                                    }}
                                >
                                    <Badge
                                        badgeContent={Object.keys(song.requests).length}
                                        color="primary"
                                    >
                                        <PlayArrow />
                                    </Badge>
                                </Fab>
                            </div>

                            <SortableList.DragHandle />
                        </SortableList.Item>
                    );
                }}
            />

            <Dialog
                open={markedSong !== null}
                onClose={() => setMarkedSong(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Typography variant="h6">
                        Mark <strong>{markedSong?.songData?.name}</strong> as played?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => setMarkedSong(null)}
                        color="primary"
                    >
                        Nevermind
                    </Button>

                    <Button
                        onClick={() => {
                            if (markedSong) {
                                markSongAsPlayed(markedSong);
                            }
                            setMarkedSong(null);
                        }}
                        color="primary"
                        autoFocus
                    >
                        Mark as played
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default QueueView;
