// Home component
import React, { useEffect, useRef, useState } from "react";
import firebase from "firebase/compat/app";
import { AppBar, Box, Button, Fab, Toolbar, Typography, Drawer, Paper, Badge, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs, FormControlLabel, Switch, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { getStorage, ref, getDownloadURL } from "firebase/storage";

import ExplicitIcon from "@mui/icons-material/Explicit";
import SearchIcon from "@mui/icons-material/Search";
import { Close, PlaylistAdd, QueueMusic, MusicNote, History, QrCode2, Favorite, FavoriteBorder } from "@mui/icons-material";

import AuthInterface from "../AuthInterface/AuthInterface";
import QueueView from "../QueueView/QueueView";
import SongSearch from "../SongSearch/SongSearch";

import "./SongPicker.css";

import Request from "../../models/Request";
import FirestoreSongData from "../../models/FirestoreSongData";
import SpotifySearchResultSimplifiedSong from "../../models/SpotifySearchResultSimplifiedSong";
import MergedSongRequest from "../../models/MergedSongRequest";
import AccountInformation from "../../models/AccountInformation";
import FirestoreUserData from "../../models/FirestoreUserData";
import QRCode from "react-qr-code";
import getSpotifyAccessToken from "../../functions/getSpotifyAccessToken";

interface FavoritesObject {
    [key: string]: SpotifySearchResultSimplifiedSong
}

interface SongPickerProps {
    auth: any,
    createAlert: (severity: string, message: string) => void,
    favoriteSongs: FavoritesObject,
    setFavoriteSongs: React.Dispatch<React.SetStateAction<FavoritesObject>>
}

const SongPicker = ({ auth, createAlert, favoriteSongs, setFavoriteSongs }: SongPickerProps) => {
    let { urlRoute } = useParams();
    urlRoute = urlRoute ? urlRoute : "";

    const navigate = useNavigate();
    const firestore = firebase.firestore();
    const storage = getStorage();

    const tabs = [
        {
            key: "requestedSongs",
            displayName: "Requests",
            icon: <SearchIcon />,
        },
        {
            key: "queuedSongs",
            displayName: "Queue",
            icon: <QueueMusic />
        },
        {
            key: "playedSongs",
            displayName: "Requests",
            icon: <History />
        }
    ];

    const [currentPage, setCurrentPage] = useState(tabs[0].key);
    const touchStartX = useRef<number | null>(null);
    const touchEndX = useRef<number | null>(null);
    const touchStartY = useRef<number | null>(null);
    const touchEndY = useRef<number | null>(null);

    // Minimum distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50;

    const onTouchStart = (e: React.TouchEvent) => {
        touchEndX.current = null;
        touchEndY.current = null;
        touchStartX.current = e.targetTouches[0].clientX;
        touchStartY.current = e.targetTouches[0].clientY;
    };

    const onTouchMove = (e: React.TouchEvent) => {
        touchEndX.current = e.targetTouches[0].clientX;
        touchEndY.current = e.targetTouches[0].clientY;
    };

    const onTouchEnd = () => {
        if (!touchStartX.current || !touchEndX.current || !touchStartY.current || !touchEndY.current) { return; }

        const distanceX = touchStartX.current - touchEndX.current;
        const distanceY = Math.abs(touchStartY.current - touchEndY.current);
        const isLeftSwipe = distanceX > minSwipeDistance;
        const isRightSwipe = distanceX < -minSwipeDistance;

        if (isLeftSwipe && distanceX > distanceY) {
            tabs.forEach((tab, index) => {
                if (tab.key === currentPage) {
                    if (tabs[index + 1]) {
                        setCurrentPage(tabs[index + 1].key);
                    }
                }
            }) 
        } else if (isRightSwipe && Math.abs(distanceX) > distanceY) {
            tabs.forEach((tab, index) => {
                if (tab.key === currentPage) {
                    if (tabs[index - 1]) {
                        setCurrentPage(tabs[index - 1].key);
                    }
                }
            })
        }
    }
    
    // @ts-ignore
    const [accountInformation, setAccountInformation] = useState<AccountInformation>({});
    const accountInformationRef = useRef(accountInformation);

    const [accountLogoUrl, setAccountLogoUrl] = useState("");
    const [songSearchOpen, setSongSearchOpen] = useState(false);

    const [removeSongDialogOpen, setRemoveSongDialogOpen] = useState(false);
    const [removeSongObj, setRemoveSongObj] = useState<MergedSongRequest | null>(null);
    const [removeSongPlayedDialogOpen, setRemovePlayedSongDialogOpen] = useState(false);
    const [removeSongPlayedObjectsArray, setRemoveSongPlayedObjectsArray] = useState<[FirestorePlayedSongData, FirestoreSongData] | null>(null);

    interface RequestsObject {
        [key: string]: Request
    }

    interface SongsObject {
        [key: string]: FirestoreSongData
    } 

    interface FirestorePlayedSongData {
        playedAt: any,
        songId: string,
        id: string 
    }

    interface PlayedSongsObject {
        [key: string]: FirestorePlayedSongData
    }

    interface FirestoreDeclinedSongData {
        declinedAt: any,
        songId: string,
        id: string
    }

    interface DeclinedSongsObject {
        [key: string]: FirestoreDeclinedSongData
    }

    interface UsersObject {
        [key: string]: FirestoreUserData
    }

    

    const [requests, setRequests] = useState<RequestsObject>({});
    const [songs, setSongs] = useState<SongsObject>({});
    const [playedSongs, setPlayedSongs] = useState<PlayedSongsObject>({});
    const [declinedSongs, setDeclinedSongs] = useState<DeclinedSongsObject>({});

    const [showAdminView, setShowAdminView] = useState(true);

    const [viewSongObj, setViewSongObj] = useState<MergedSongRequest | null>(null);
    const [songQueue, setSongQueue] = useState<string[]>([]);
    const [users, setUsers] = useState<UsersObject>({});
    const [songToDecline, setSongToDecline] = useState<MergedSongRequest | null>(null);
    const [showQRCodeModal, setShowQRCodeModal] = useState(false);
    const [showNeedToSignInModal, setShowNeedToSignInModal] = useState(false);

    const songSearchStateRef = useRef({
        searchText: "",
        searchResults: []
    });

    // Check if user is signed in from auth
    const signedIn = auth.currentUser !== null;

    useEffect(() => {
        // @ts-ignore
        getAccountInformation(urlRoute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Attempt to refresh the spotify api token
    useEffect(() => {
        const asyncWrapperSpotify = async () => {
            await getSpotifyAccessToken();
        }

        asyncWrapperSpotify();
    }, [])

    // Force reload the page if the current user changes
    // Listen for changes in the account's validated requests, only listen to new requests
    // where the requestDate is greater than 16 hours ago

    useEffect(() => {
        
        const accountRequestsRef = firestore.collection("accounts").doc(accountInformationRef.current.id).collection("requests").where("requestedAt", ">", (new Date(Date.now() - (16*60*60*1000))));
        const unsubscribeAccountReuests = accountRequestsRef.onSnapshot((querySnapshot) => {
            let tempRequests: RequestsObject = {};
            querySnapshot.forEach((doc) => {
                tempRequests[doc.id] = doc.data() as Request;
                // Set the id
                tempRequests[doc.id].id = doc.id;
            });

            // Iterate through the requests and remove any that are not active
            Object.values(tempRequests).forEach((request) => {
                if (request.requestActive === false) {
                    delete tempRequests[request.id];
                }
            });

            // If there are any duplicate requests, remove all but the most recent
            // A request is defined as having the same songId and userId
            const requestIds = Object.values(tempRequests).map((request) => request.songId + request.userId);
            const uniqueRequestIds = [...new Set(requestIds)];
            const uniqueRequests: RequestsObject = {};
            uniqueRequestIds.forEach((requestId) => {
                const request = Object.values(tempRequests).find((request) => request.songId + request.userId === requestId);

                if (request) {
                    uniqueRequests[request.id] = request;
                }
            });

            setRequests(uniqueRequests);
        });

        const accountSongsRef = firestore.collection("accounts").doc(accountInformationRef.current.id).collection("playedSongs").where("playedAt", ">", (new Date(Date.now() - (16*60*60*1000))));
        const unsubscribeAccountSongs = accountSongsRef.onSnapshot((querySnapshot) => {
            let tempSongs: SongsObject = {};
            querySnapshot.forEach((doc) => {
                tempSongs[doc.id] = doc.data() as FirestoreSongData;
                // Set id
                tempSongs[doc.id].id = doc.id;
            });
            setSongs(tempSongs);
        });

        // Get songs played in last 16 hours by current account
        const accountPlayedSongsRef = firestore.collection("accounts").doc(accountInformationRef.current.id).collection("playedSongs").where("playedAt", ">", (new Date(Date.now() - (16*60*60*1000))));
        const unsubscribeAccountPlayedSongs = accountPlayedSongsRef.onSnapshot((querySnapshot) => {
            let tempPlayedSongs: PlayedSongsObject = {};
            querySnapshot.forEach((doc) => {
                tempPlayedSongs[doc.id] = doc.data() as FirestorePlayedSongData;
                // Set id
                tempPlayedSongs[doc.id].id = doc.id;
            });

            // For each item change server timestamp to milliseconds since epoch
            Object.values(tempPlayedSongs).forEach((playedSong) => {
                playedSong.playedAt = playedSong.playedAt.toMillis();
            });

            setPlayedSongs(tempPlayedSongs);
        });

        // Get songs declined in the last 16 hours by current account
        const accountDeclinedSongsRef = firestore.collection("accounts").doc(accountInformationRef.current.id).collection("declinedSongs").where("declinedAt", ">", (new Date(Date.now() - (16*60*60*1000))));
        const unsubscribeAccountDeclinedSongs = accountDeclinedSongsRef.onSnapshot((querySnapshot) => {
            let tempDeclinedSongs: DeclinedSongsObject = {};
            querySnapshot.forEach((doc) => {
                tempDeclinedSongs[doc.id] = doc.data() as FirestoreDeclinedSongData;
                // Set id
                tempDeclinedSongs[doc.id].id = doc.id;
            });

            // For each item change server timestamp to milliseconds since last epoch
            Object.values(tempDeclinedSongs).forEach((declinedSong) => {
                declinedSong.declinedAt = declinedSong.declinedAt.toMillis();
            });

            setDeclinedSongs(tempDeclinedSongs);
        });

        // Get user data
        const usersRef = firestore.collection("UserData");
        const unsubscribeUserData = usersRef.onSnapshot((querySnapshot) => {
            let tempUsers: UsersObject = {};
            querySnapshot.forEach((doc) => {
                tempUsers[doc.id] = doc.data() as FirestoreUserData;
                // Set id
                tempUsers[doc.id].id = doc.id;
            });

            setUsers(tempUsers);
        });

        
        return () => {
            unsubscribeAccountReuests();
            unsubscribeAccountSongs();
            unsubscribeAccountPlayedSongs();
            unsubscribeAccountDeclinedSongs();
            unsubscribeUserData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInformation]);

    useEffect(() => {
        const resetQueue = async () => {
            try {
                if (accountInformationRef.current?.queueLastUpdated !== undefined && songQueue.length > 0) {
                    if (((new Date()).getTime() - (new Date(accountInformationRef?.current?.queueLastUpdated?.seconds * 1000)).getTime()) > (16*60*60*1000)) {
                        // removeSongFromQueue([], "")
                        const accountRef = firestore.collection("accounts").doc(accountInformationRef.current.id);
                        await accountRef.set({
                            songQueue: [],
                            queueLastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
                            lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
                        }, { merge: true });
                        setSongQueue([]);
                        createAlert("success", "Queue Reset");
                    }
                }
            } catch (error) {
                console.error("Error updating document: " + error);
                createAlert("error", "Error refreshing queue");
            }
        }
        resetQueue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [songQueue]);

    const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
        setCurrentPage(tabs[newValue].key);
    }

    const updateQueue = async (_newQueue: string[], _successMessage: string, _errorMessage: string) => {
        const accountRef = firestore.collection("accounts").doc(accountInformationRef.current.id);

        // Add the new id to the end of the queue
        setSongQueue(_newQueue);
        
        try {
            await accountRef.set({
                songQueue: _newQueue,
                queueLastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
            }, { merge: true });
            createAlert("success", _successMessage);
        } catch (error) {
            console.error("Error updating document: ", error);
            createAlert("error", _errorMessage);
        }
    }

    const addSongToQueue = (_songQueue: string[], _newId: string) => {
        _songQueue.push(_newId);
        updateQueue(_songQueue, "Song added to queue", "Error adding song to the queue");
    }

    const removeSongFromQueue = (_songQueue: string[], _removeId: string) => {
        const newQueue = _songQueue.filter((id) => id !== _removeId);
        updateQueue(newQueue, "Song removed from queue", "Error removing song from the queue");
    }

    const getAccountInformation = (_urlRoute: string) => {
        const tempAccountInfoRef = firestore.collection("accounts").where("urlRoute", "==", _urlRoute.toLowerCase()).limit(1);
        tempAccountInfoRef.get().then((querySnapshot) => {
            // Check if querySnapshot is empty
            if (querySnapshot.empty) {
                navigate("/notfound");
            } else {
                // Get the first document
                let tempAccountInfo = querySnapshot.docs[0].data() as AccountInformation;
                tempAccountInfo.id = querySnapshot.docs[0].id;
                setAccountInformation(tempAccountInfo);
                accountInformationRef.current = tempAccountInfo;

                // Get account logo from storage
                const logoRef = ref(storage, `accounts/${querySnapshot.docs[0].id}/logo.png`);

                getDownloadURL(logoRef).then((url) => {
                    setAccountLogoUrl(url);
                }).catch((error) => {
                    console.error("Error getting documents: ", error);
                });

                // Subscribe to the account id
                const accountRef = firestore.collection("accounts").doc(tempAccountInfo.id);
                accountRef.onSnapshot((doc) => {
                    if (doc.exists) {
                        tempAccountInfo = doc.data() as AccountInformation;
                        tempAccountInfo.id = doc.id;
                        setAccountInformation(tempAccountInfo);
                        accountInformationRef.current = tempAccountInfo;

                        if (tempAccountInfo?.songQueue?.length > 0) {
                            setSongQueue(tempAccountInfo.songQueue);
                        }
                    }
                });
            }
        }).catch((error) => {
            console.error("Error getting documents: ", error);
            // navigate(/notfound);
        });
    }

    interface MergedSongRequestsOutput {
        [key: string]: MergedSongRequest
    }

    const mergeSongRequests = (_requests: RequestsObject, _songs: SongsObject, _queue: string[], _playedSongs: PlayedSongsObject, _declinedSongs: DeclinedSongsObject): MergedSongRequest[] => {

        try {
            const tempRequests: RequestsObject = { ..._requests };
            const tempSongs: SongsObject = {};

            // For each request, get the song
            Object.values(_requests).forEach((request) => {
                // If the request has the required fields in song metadata, add the song to tempSongs
                if (request.songMetadata !== undefined) {
                    tempSongs[request.songMetadata.id] = {
                        id: request.songMetadata.id,
                        lastRequested: request.requestedAt,
                        songData: request.songMetadata,
                        songId: request.songMetadata.id
                    }
                }
            });

            // For each song in _songs, add the song to the tempSongs
            Object.values(_songs).forEach((song) => {
                if (song.songData !== undefined) {
                    tempSongs[song.id] = {
                        id: song.id,
                        lastRequested: song.lastRequested,
                        songData: song.songData,
                        songId: song.id
                    }
                }
            });

            const outputSongs: MergedSongRequestsOutput = {};

            Object.values(tempSongs).forEach((song) => {

                outputSongs[song.id] = {
                    ...song,
                    requests: {},
                    userHasRequested: false,
                    songHasPlayedRecently: false,
                    songInQueue: false,
                    songHasBeenDeclinedRecently: false
                }

                Object.values(tempRequests).forEach((request) => {
                    // If the requset is for the song, add the request to the song and delete the request from tempRequests
                    if (request.songId === song.id && request.requestActive === true) {
                        outputSongs[song.id]["requests"][request.userId] = request;
                        delete tempRequests[request.id]
                    }
                });
            });

            Object.keys(outputSongs).forEach((key) => {
                const song = outputSongs[key];
                const userHasRequested = song.requests[auth.currentUser?.uid] !== undefined;
                let songHasPlayedRecently = false;
                let songHasBeenDeclinedRecently = false;

                for (let playedSong in _playedSongs) {
                    const historyObj = _playedSongs[playedSong];
                    if (historyObj.songId === song.id) {
                        songHasPlayedRecently = true;
                    }
                }

                for (let declinedSong in _declinedSongs) {
                    const historyObj = _declinedSongs[declinedSong];
                    if (historyObj.songId === song.id) {
                        songHasBeenDeclinedRecently = true;
                    }
                }

                outputSongs[key]["userHasRequested"] = userHasRequested;
                outputSongs[key]["songHasPlayedRecently"] = songHasPlayedRecently;
                outputSongs[key]["songInQueue"] = _queue.includes(song.id);
                outputSongs[key]["songHasBeenDeclinedRecently"] = songHasBeenDeclinedRecently;
            });

            return Object.values(outputSongs).filter((song) => Object.keys(song.requests).length > 0);
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    const unRequestSong = async (_requestSongObj: MergedSongRequest) => {
        try {
            if (_requestSongObj !== undefined) {
                if (_requestSongObj?.requests?.[auth.currentUser?.uid] !== undefined) {
                    // Find request in validatedRequests variable locally
                    const requestObj = Object.values(requests).find((request) => {
                        return request.songId === _requestSongObj.id && request.userId === auth.currentUser.uid;
                    });

                    if (requestObj) {
                        // Set requestActive to false locally
                        requestObj.requestActive = false;

                        setRequests({
                            ...requests,
                            [requestObj.id]: {
                                ...requestObj,
                                requestActive: false
                            }
                        });

                        // Update the request in the database
                        const requestRef = firestore.collection("accounts").doc(accountInformationRef.current.id).collection("requests").doc(requestObj.id);

                        await requestRef.set({
                            requestActive: false
                        }, { merge: true });
                        createAlert("success", "Request removed");
                    }
                }
            }
        } catch (error) {
            console.error(error);
            createAlert("error", "Error unrequesting song");
        }
    }

    const requestSong = async (_song: SpotifySearchResultSimplifiedSong) => {
        const songMetadata: SpotifySearchResultSimplifiedSong = {
            album: _song.album,
            artists: _song.artists,
            explicit: _song.explicit,
            id: _song.id,
            name: _song.name,
            thumbnail: _song.thumbnail,
            uri: _song.uri
        }

        const requestObj = {
            songId: _song.id,
            requestActive: true,
            userId: auth.currentUser.uid,
            userName: auth.currentUser.displayName,
            userProfilePhotoUrl: auth.currentUser.photoURL,
            requestedAt: firebase.firestore.FieldValue.serverTimestamp(),
            songMetadata: songMetadata
        }

        const localRequestObj = {
            requestActive: true,
            requestDate: (new Date()).getTime(),
            songId: _song.id,
            userMetadata: {
                userId: auth.currentUser.uid,
                userName: auth.currentUser.displayName,
                userProfileUrl: auth.currentUser.photoURL
            },
            songMetadata: songMetadata
        }

        // Add the request to the local state
        setRequests({
            ...requests,
            [(new Date()).getTime()]: localRequestObj
        });

        const userRequestsRef = firestore.collection("accounts").doc(accountInformationRef.current.id).collection("requests");
        try {
            await userRequestsRef.add(requestObj);

            // Clear the search results and search text
            songSearchStateRef.current.searchResults = [];
            songSearchStateRef.current.searchText = "";
            createAlert("success", `${_song?.name} requested`);
            setSongSearchOpen(false);
        } catch (error) {
            console.error("Error adding document: ", error);
            createAlert("error", "Error requesting song");
            setSongSearchOpen(false);
        }
    }

    const markSongAsPlayed = async (_song: MergedSongRequest) => {

        // Add the song to accounts/{accountId}/playedSongs
        const songPlayedObj = {
            songId: _song.id,
            playedAt: firebase.firestore.FieldValue.serverTimestamp()
        }

        const localSongPlayedObj = {
            songId: _song.id,
            playedAt: (new Date()).getTime()
        }

        setPlayedSongs({
            ...playedSongs,
            [(new Date()).getTime()]: localSongPlayedObj
        });

        try {
            const songsPlayedRef = firestore.collection("accounts").doc(accountInformationRef.current.id).collection("playedSongs");

            await songsPlayedRef.add(songPlayedObj);

            removeSongFromQueue(songQueue, _song.id);
        } catch (error) {
            console.error("Error adding document: ", error);
            createAlert("error", "Error marking song as played");
        }
    }

    const markSongAsUnplayed = async (_song: FirestorePlayedSongData) => {
        try {
            await firestore.collection("accounts").doc(accountInformationRef.current.id).collection("playedSongs").doc(_song.id).delete();
        } catch (error) {
            console.error("Error deleting document: ", error);
            createAlert("error", "Error marking song as unplayed");
        }
    }

    const markSongAsDeclined = async (_song: MergedSongRequest) => {
        const songDeclinedObj = {
            songId: _song.id,
            declinedAt: firebase.firestore.FieldValue.serverTimestamp()
        }

        const localSongDeclinedObj = {
            songId: _song.id,
            declinedAt: (new Date()).getTime()
        }

        setDeclinedSongs({
            ...declinedSongs,
            [(new Date()).getTime()]: localSongDeclinedObj
        });

        try {
            await firestore.collection("accounts").doc(accountInformationRef.current.id).collection("declinedSongs").add(songDeclinedObj);
        } catch (error) {
            console.error("Error adding document: ", error);
            createAlert("error", "Error marking song as declined");
        }
    }

    const addSongToFavorites = async (_song: SpotifySearchResultSimplifiedSong) => {
        setFavoriteSongs({ ...favoriteSongs, _song});
        try {
            await firestore.collection("UserFavorites").doc(auth.currentUser?.uid).update({
                [`favoriteSongs.${_song.id}`]: _song
            });
            createAlert("success", "Added song to favorites");
        } catch (error) {
            console.error("Error adding document: ", error);
            createAlert("error", "Error adding song to favorites");
        }
    }

    const removeSongFromFavorites = async (_song: SpotifySearchResultSimplifiedSong) => {
        delete favoriteSongs[_song.id];
        setFavoriteSongs(favoriteSongs);

        try {
            await firestore.collection("UserFavorites").doc(auth.currentUser?.uid).update({
                [`favoriteSongs.${_song.id}`]: firebase.firestore.FieldValue.delete() 
            });
            createAlert("success", "Removed song from favorites");
        } catch (error) {
            console.error("Error adding document: ", error);
            createAlert("error", "Error removing song from favorites");
        }
    }

    const admins = accountInformation?.admins !== undefined ? accountInformation?.admins : {};
    const isAdmin = admins[auth.currentUser?.uid] === true;
    const adminView = isAdmin && showAdminView;

    const mergedSongObjects = mergeSongRequests(requests, songs, songQueue, playedSongs, declinedSongs);
    const groupedHistory = Object.values(playedSongs);

    const queuedSongs = songQueue.filter((songId, index) => {
        const song = mergedSongObjects.find((song) => song.id === songId);

        if (song === undefined) {
            return false;
        } else {
            return true;
        }
    });

    const requestObjects = mergedSongObjects
                            .sort((a, b) => {
                                return Object.keys(b.requests).length - Object.keys(a.requests).length;
                            })
                            .filter((song) => {
                                return !song.songHasPlayedRecently && !song.songHasBeenDeclinedRecently && !song.songInQueue;
                            });

    return (
        <Box className="songPickerAppContent">
            <AppBar position="static">
                <Toolbar>
                    {
                        accountLogoUrl !== "" ? (
                            <img 
                                src={accountLogoUrl}
                                alt="account logo"
                                className="accountLogo" 
                                style={{
                                    width: "40px",
                                    height: "40px",
                                    marginLeft: "0px",
                                    marginRight: "5px"
                                }}
                            />
                        ) : (
                            <div className="accountLogo"></div>
                        )
                    }
                    {
                        isAdmin ? (
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="secondary"
                                        defaultChecked
                                        value={showAdminView}
                                        onChange={(e) => {
                                            setShowAdminView(e.target.checked);
                                        }}
                                    />
                                }
                                label="Admin View"
                                style={{ marginLeft: "15px" }}
                            />
                        ) : (
                            null
                        )
                    }
                    <IconButton
                        onClick={() => setShowQRCodeModal(!showQRCodeModal)}
                        size="large"
                    >
                        <QrCode2 htmlColor="white"/>
                    </IconButton>
                    <div style={{ flexGrow: 1 }}></div>
                    <AuthInterface auth={auth} createAlert={createAlert} />
                </Toolbar>
            </AppBar>
            <Paper elevation={4}>
                <Tabs
                    indicatorColor="secondary"
                    variant="fullWidth"
                    value={
                        tabs.findIndex((e) => {
                            return e.key === currentPage;
                        })
                    }
                    onChange={handleTabChange}
                    aria-label="tabs"
                >
                    {
                        tabs.map((tab) => {
                            return (<Tab
                                        sx={{ bgcolor: "secondary" }}
                                        icon={tab.icon}
                                        key={tab.key}
                                    />);
                        })
                    }
                </Tabs>
            </Paper>

            <div className="songPickerContent">
                
                { /* Queue Page */ }
                <div 
                    className="songPickerSelectorArea"
                    style={{ display: currentPage === "queuedSongs" ? "flex" : "none" }}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                >
                    <h3>Queue</h3>
                    <div
                        className="noItemsFound"
                        style={{ display: queuedSongs.length === 0 ? "flex" : "none" }}
                    >
                        Quit snoozin' DJ, add some songs!
                    </div>
                    {
                        adminView ? (
                            <QueueView
                                songQueue={songQueue}
                                songs={mergedSongObjects}
                                markSongAsPlayed={markSongAsPlayed}
                                updateQueue={updateQueue}
                                setViewSongObj={setViewSongObj}
                            />
                        ) : (
                            songQueue.map((songId, index) => {
                                const song = mergedSongObjects.find((song) => song.id === songId);
                                if (song === undefined) {
                                    return null;
                                }

                                return (
                                    <div className="songPickerRequest" key={song.id}>
                                        <img
                                            className="songPickerRequestImage"
                                            src={song?.songData?.thumbnail}
                                            alt={song?.songData?.name}
                                            onClick={() => setViewSongObj(song)}
                                        />
                                        <div
                                            className="songPickerRequestTextContent"
                                            onClick={() => setViewSongObj(song)}
                                        >
                                            <div className="songPickerRequestName">
                                                {song?.songData?.name}
                                            </div>
                                            <div className="songPickerRequestArtists">
                                                {
                                                    song?.songData?.explicit ? (
                                                        <ExplicitIcon
                                                            htmlColor="gray"
                                                            style={{
                                                                fontSize: "14px",
                                                                marginRight: "3px"
                                                            }}
                                                        />
                                                    ) : (
                                                        null
                                                    )
                                                }
                                                {song?.songData?.artists}
                                            </div>
                                            <div className="songPickerRequestArtists">
                                                {
                                                    index === 0 ? (
                                                        "Song up next"
                                                    ) : (
                                                        ""
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="songPickerRequestActionArea">
                                             <IconButton
                                                style={{ paddingRight: "20px" }}
                                                onClick={() => {
                                                    favoriteSongs[song?.songId] ? (
                                                        removeSongFromFavorites({...song?.songData, id: song?.songId })
                                                    ) : (
                                                        addSongToFavorites({...song?.songData,
                                                            id: song?.songId    
                                                        })
                                                    )
                                                }}
                                            >
                                                    {   signedIn && (
                                                        favoriteSongs[song?.songId] ? (
                                                            <Favorite htmlColor="IndianRed" />
                                                        ) : (
                                                            <FavoriteBorder />
                                                        ))
                                                    }
                                            </IconButton>


                                            <Fab
                                                // @ts-ignore
                                                color={
                                                    signedIn ? (
                                                        song?.userHasRequested ? (
                                                            "secondary"
                                                        ) : (
                                                            "primary"
                                                        )
                                                    ) : (
                                                        "gray"
                                                    )
                                                }
                                                onClick={() => {
                                                    if (signedIn) {
                                                        if (song.userHasRequested) {
                                                            setRemoveSongDialogOpen(true);
                                                            setRemoveSongObj(song);
                                                        } else {
                                                            requestSong({
                                                                ...song?.songData,
                                                                id: song.id
                                                            });
                                                        }
                                                    } else {
                                                        setShowNeedToSignInModal(true);
                                                    }
                                                }}
                                            >
                                                <Badge
                                                    badgeContent={Object.keys(song.requests).length}
                                                    color="primary"
                                                >
                                                    {
                                                        song?.songInQueue ? (
                                                            <QueueMusic htmlColor={"white"} />
                                                        ) : (
                                                            <MusicNote htmlColor={"white"} />
                                                        )
                                                    }
                                                </Badge>
                                            </Fab>
                                        </div>
                                    </div>
                                );
                            })
                        )
                    }
                    <div style={{ 
                        height: "20vh",
                        width: "100%",
                        clear: "both" }}>

                    </div>
                </div>

                { /* Requests Page */}
                <div
                    className="songPickerSelectorArea"
                    style={{ display: currentPage === "requestedSongs" ? "flex" : "none" }}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                >
                    <h3>Requests</h3>
                    <div
                        className="noItemsFound"
                        style={{ display: requestObjects.length === 0 ? "flex" : "none" }}
                    >
                        No songs requested yet!
                    </div>
                    {
                        requestObjects.map((song) => {
                            return (
                                <div className="songPickerRequest" key={song.id}>
                                    <img
                                        className="songPickerRequestImage"
                                        src={song?.songData?.thumbnail}
                                        alt={song?.songData?.name}
                                        onClick={() => {
                                            setViewSongObj(song);
                                        }}
                                    />
                                    <div
                                        className="songPickerRequestTextContent"
                                        onClick={() => setViewSongObj(song)}
                                    >
                                        <div className="songPickerRequestName">
                                            {song?.songData?.name}
                                        </div>
                                        <div className="songPickerRequestArtists">
                                            {
                                                song?.songData?.explicit ? (
                                                    <ExplicitIcon
                                                        htmlColor="gray"
                                                        style={{
                                                            fontSize: "14px",
                                                            marginRight: "3px"
                                                        }}
                                                    />
                                                ) : (
                                                    null
                                                )
                                            }
                                            <span>{song?.songData?.artists}</span>
                                        </div>
                                    </div>
                                    <div className="songPickerRequestActionArea">
                                        <IconButton
                                            style={{ paddingRight: "20px" }}
                                            onClick={() => {
                                                favoriteSongs[song?.songId] ? (
                                                    removeSongFromFavorites({...song?.songData, id: song?.songId })
                                                ) : (
                                                    addSongToFavorites({...song?.songData,
                                                        id: song?.songId    
                                                    })
                                                )
                                            }}
                                        >
                                                {   signedIn && (
                                                    favoriteSongs[song?.songId] ? (
                                                        <Favorite htmlColor="IndianRed" />
                                                    ) : (
                                                        <FavoriteBorder />
                                                    ))
                                                }
                                        </IconButton>

                                        <Fab
                                            // @ts-ignore
                                            color={
                                                signedIn ? (
                                                    adminView ? (
                                                        song?.songInQueue ? (
                                                            "secondary"
                                                        ) : (
                                                            "primary"
                                                        )
                                                    ) : (
                                                        song?.userHasRequested ? (
                                                            "secondary"
                                                        ) : (
                                                            "primary"
                                                        )
                                                    )
                                                ) : (
                                                    "gray" 
                                                )
                                            }
                                            onClick={() => {
                                                if (signedIn) {
                                                    if (adminView) {
                                                        if (song?.songInQueue) {
                                                            removeSongFromQueue(songQueue, song.id);
                                                        } else {
                                                            addSongToQueue(songQueue, song.id);
                                                        }
                                                    } else {
                                                        if (song.userHasRequested) {
                                                            setRemoveSongDialogOpen(true);
                                                            setRemoveSongObj(song);
                                                        } else {
                                                            requestSong({
                                                                ...song?.songData,
                                                                id: song.id
                                                            });
                                                        }
                                                    }
                                                } else {
                                                    setShowNeedToSignInModal(true);
                                                }
                                            }}
                                        >
                                            <Badge
                                                badgeContent={Object.keys(song.requests).length}
                                                color="primary"
                                            >
                                                {
                                                    adminView ? (
                                                        song?.songInQueue ? (
                                                            <QueueMusic htmlColor={"white"} />
                                                        ) : (
                                                            <PlaylistAdd htmlColor={"white"} />
                                                        )
                                                    ) : (
                                                        song?.songInQueue ? (
                                                            <QueueMusic htmlColor={"white"} />
                                                        ) : (
                                                            <MusicNote htmlColor={"white"} />
                                                        )
                                                    )
                                                }
                                            </Badge>
                                        </Fab>
                                    </div>
                                </div>
                            );
                        })
                    }
                    <div style={{ 
                        display: requestObjects.length !== 0 ? "flex" : "none",
                        height: "20vh",
                        width: "100%",
                        clear: "both" }}>

                    </div>
                </div>

                { /* History Page */ }
                <div
                    className="songPickerSelectorArea"
                    style={{ display: currentPage === "playedSongs" ? "flex" : "none" }}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                >
                    <h3>History</h3>
                    <div
                        className="noItemsFound"
                        style={{ display: groupedHistory.length === 0 ? "flex" : "none" }}
                    >
                        The night is still young
                    </div>
                    {
                        groupedHistory
                        .sort((a, b) => {
                            return b.playedAt - a.playedAt;
                        })
                        .map((playedSong) => {
                            const song = songs[playedSong.songId];
                            const time = (new Date(playedSong.playedAt)).toLocaleTimeString();

                            let [songInMergedSongObjects] = mergedSongObjects.filter((song) => {
                                return song.id === playedSong.songId;
                            });

                            // If the song doesn't exist, we set it to a dummy song object
                            if (!songInMergedSongObjects) {
                                songInMergedSongObjects = {
                                    songData: {
                                        album: "",
                                        artists: "",
                                        explicit: false,
                                        name: "",
                                        thumbnail: "",
                                        uri: ""
                                    },
                                    songId: "",
                                    id: "",
                                    requests: {},
                                    userHasRequested: false,
                                    songHasPlayedRecently: false,
                                    songHasBeenDeclinedRecently: false,
                                    songInQueue: false,
                                    lastRequested: undefined,

                                }
                            }

                            return (
                                <div className="songPickerRequest" key={playedSong.id}>
                                    <img
                                        className="songPickerRequestImage"
                                        src={songInMergedSongObjects?.songData?.thumbnail}
                                        alt={songInMergedSongObjects?.songData?.name}
                                        onClick={() => {
                                            setViewSongObj(songInMergedSongObjects);
                                        }}
                                    />
                                    <div
                                        className="songPickerRequestTextContent"
                                        onClick={() => {
                                            setViewSongObj(songInMergedSongObjects);
                                        }}
                                    >
                                        <div className="songPickerRequestName">
                                            {songInMergedSongObjects?.songData?.name}
                                        </div>
                                        <div className="songPickerRequestArtists">
                                            {
                                                songInMergedSongObjects?.songData?.explicit ? (
                                                    <ExplicitIcon
                                                        htmlColor="gray"
                                                        style={{
                                                            fontSize: "14px",
                                                            marginRight: "3px"
                                                        }}
                                                    />
                                                ) : (
                                                    null
                                                )
                                            }
                                            {
                                                songInMergedSongObjects?.songData?.artists
                                            }
                                        </div>
                                        <div className="songPickerRequestArtists">
                                            Played at {time.substring(0, time.length - 6) + time.substring(time.length - 3)}
                                        </div>
                                    </div>
                                    <div className="songPickerRequestActionArea">
                                        <IconButton
                                            style={{ paddingRight: "20px" }}
                                            onClick={() => {
                                                favoriteSongs[songInMergedSongObjects?.songId] ? (
                                                    removeSongFromFavorites({...songInMergedSongObjects?.songData, id: songInMergedSongObjects?.songId })
                                                ) : (
                                                    addSongToFavorites({...songInMergedSongObjects?.songData,
                                                        id: songInMergedSongObjects?.songId    
                                                    })
                                                )
                                            }}
                                        >
                                            {   signedIn && (
                                                favoriteSongs[songInMergedSongObjects?.songId] ? (
                                                    <Favorite htmlColor="IndianRed" />
                                                ) : (
                                                    <FavoriteBorder />
                                                ))
                                            }
                                        </IconButton>

                                        {
                                            adminView && (
                                                <Button
                                                    onClick={() => {
                                                        setRemovePlayedSongDialogOpen(true);
                                                        setRemoveSongPlayedObjectsArray([playedSong, song]);
                                                    }}
                                                >
                                                    <Close />
                                                </Button>
                                            )
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                    <div style={{ 
                        height: "20vh",
                        width: "100%",
                        clear: "both" }}>

                    </div>
                </div>
                
            </div>
            <Fab
                // @ts-ignore
                color={
                    signedIn ? (
                        "secondary"
                    ) : (
                        "gray"
                    )
                }
                aria-label="Search for a song"
                className="songPickerSelectorButton"
                variant="extended"
                disabled={false}
                onClick={() => {
                    if (signedIn) {
                        setSongSearchOpen(true);
                    } else {
                        setShowNeedToSignInModal(true);
                    }
                }}
                style={{ display: currentPage === "requestedSongs" ? "flex" : "none" }}
            >
                <SearchIcon htmlColor="white" />
                <Typography
                    variant="h6"
                    style={{
                        paddingLeft: "12px",
                        color: "white"
                    }}
                >
                    Search for a song
                </Typography>
            </Fab>
            <Drawer
                anchor={"bottom"}
                open={songSearchOpen}
                onClose={() => { setSongSearchOpen(false); }}
                sx={{
                    "& .MuiDrawer-paper": {
                        borderRadius: "5px 5px 0px 0px"
                    },
                    display: signedIn ? "flex" : "none"
                }}
            >
                <Paper className="songPickerSearchDrawer">
                    <SongSearch
                        auth={auth}
                        createAlert={createAlert}
                        mergedSongObjects={mergedSongObjects}
                        requestSong={requestSong}
                        songSearchState={songSearchStateRef.current}
                        unRequestSong={unRequestSong}
                        favoriteSongs={favoriteSongs}
                        addSongToFavorites={addSongToFavorites}
                        removeSongFromFavorites={removeSongFromFavorites}
                        addSongToQueue={addSongToQueue}
                        songQueue={songQueue}
                        adminView={adminView}
                    />
                </Paper>
            </Drawer>

            { /* Confirm Request Removal dialog */}
            <Dialog
                open={removeSongDialogOpen}
                onClose={() => { setRemoveSongDialogOpen(false); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Remove your request?</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">
                        Are you sure you want to unrequest <strong>{removeSongObj?.songData?.name}</strong>?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setRemoveSongDialogOpen(false);
                        }}
                        color="primary"
                    >
                        Nevermind
                    </Button>
                    <Button
                        onClick={() => {
                            setRemoveSongDialogOpen(false);
                            if (removeSongObj) {
                                unRequestSong(removeSongObj);
                            }
                        }}
                        color="primary"
                        autoFocus
                    >
                        Remove it!
                    </Button>
                </DialogActions>
            </Dialog>

            { /* Confirm remove song from history dialog */ }
            <Dialog
                open={removeSongPlayedDialogOpen}
                onClose={() => { setRemovePlayedSongDialogOpen(false); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Remove from played songs?</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">
                        Are you sure you want to remove <strong>{removeSongPlayedObjectsArray && removeSongPlayedObjectsArray[1]?.songData?.name}</strong>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setRemovePlayedSongDialogOpen(false);
                        }}
                        color="primary"
                    >
                        Nevermind
                    </Button>
                    <Button
                        onClick={() => {
                            setRemovePlayedSongDialogOpen(false);
                            if (removeSongPlayedObjectsArray) {
                                markSongAsUnplayed(removeSongPlayedObjectsArray[0]);
                            }
                        }}
                        color="primary"
                        autoFocus
                    >
                        Remove it!
                    </Button>
                </DialogActions>
            </Dialog>

            { /* View who has requested the song dialog */ }
            <Dialog
                open={viewSongObj !== null}
                onClose={() => setViewSongObj(null)}
            >
                <DialogTitle
                    sx={{ m: 0, p: 2 }}
                    id="customized-dialog-title"
                >
                    <strong>{ viewSongObj?.songData?.name }</strong> has been requested by:
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {
                            viewSongObj && Object.keys(viewSongObj?.requests).map((request) => {
                                console.log(users[request]?.photoURL);
                                return (
                                    <div className="songRequestedByRow" key={request}>
                                        <img
                                            src={users[request]?.photoURL}
                                            alt={users[request]?.fbDisplayName}
                                            className="profileImg"
                                        />
                                        <p style={{ paddingLeft: "8px" }}>
                                            {users[request]?.fbDisplayName}
                                        </p>
                                    </div>
                                );
                            })
                        }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {
                        adminView && currentPage !== "playedSongs" && (
                            <Button
                                onClick={()=> {
                                    setSongToDecline(viewSongObj);
                                }}
                            >
                                Decline Request
                            </Button>
                        )
                    }
                    <Button
                        autoFocus
                        onClick={() => setViewSongObj(null)}
                    >
                        Close
                    </Button>
                </DialogActions>

                { /* Dialog to confirm declining a song request */ }
                <Dialog
                    open={songToDecline !== null}
                    onClose={() => setSongToDecline(null)}
                >
                    <DialogTitle
                        sx={{ m: 0, p: 2 }}
                        id="customized-dialog-title"
                    >
                        Really decline <strong>{songToDecline?.songData?.name}</strong>
                    </DialogTitle>
                    <DialogContent dividers>
                        This will hide the song from the requests tab, and let users know that the song won't be played tonight.
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                if (songToDecline) {
                                    markSongAsDeclined(songToDecline);
                                }
                                setSongToDecline(null);
                                setViewSongObj(null);
                            }}
                        >
                            Decline Request
                        </Button>
                    </DialogActions>
                </Dialog>
            </Dialog>
            
            { /* Dialog to show QR code for current account */ }
            <Dialog
                open={showQRCodeModal}
                onClose={() => setShowQRCodeModal(false)}
            >
                <DialogContent>
                    <QRCode value={`https://honkytonk.app/${accountInformationRef.current.urlRoute}`} />
                </DialogContent>
            </Dialog>

            { /* Dialog to tell the user they need to sign in to request a song */ }
            <Dialog
                open={showNeedToSignInModal}
                onClose={() => setShowNeedToSignInModal(false)}
            >
                <DialogTitle
                    sx={{ m: 0, p: 2 }}
                    id="customized-dialog-title"
                >
                    Oops!
                </DialogTitle>
                <DialogContent>
                    You need to be signed in to request songs; you can do so by clicking the button in the top right of the screen.
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default SongPicker;
