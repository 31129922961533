import { useMemo, useState, ReactNode, Fragment } from "react";
import { Active, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";

// Import styles
import "./SortableList.css";

import { DragHandle, SortableItem } from "./components/SortableItem/index";
import { SortableOverlay } from "./components/SortableOverlay/index";

interface SortableListProps {
    items: any[],
    onChange: any,
    renderItem: (item: { id: string}) => ReactNode
}

export const SortableList = ({ items, onChange, renderItem }: SortableListProps) => {

    const [active, setActive] = useState<Active | null>(null);

    const activeItem = useMemo(() => {
        return items.find((item) => item.id === active?.id)
    }, [active, items]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );


    return (
        <DndContext
            sensors={sensors}
            onDragStart={({ active }) => setActive(active)}
            onDragEnd={({ active, over }) => {
                if (over && active.id !== over?.id) {
                    const activeIndex = items.findIndex(({ id }) => {
                        return id === active.id;
                    });
                    const overIndex = items.findIndex(({ id }) => {
                        return id === over.id;
                    });

                    onChange(arrayMove(items, activeIndex, overIndex));
                }
                setActive(null);
            }}
            onDragCancel={() => setActive(null)}
        >
            <SortableContext items={items}>
                <ul
                    className="SortableList"
                    role="application"
                >
                    {
                        items.map((item) => {
                            return (
                                <Fragment key={item.id}>
                                    {renderItem(item)}
                                </Fragment>
                            );
                        })
                    }
                </ul>
            </SortableContext>

            <SortableOverlay>
                {
                    activeItem ? (
                        renderItem(activeItem)
                    ) : (
                        null
                    )
                }
            </SortableOverlay>
        </DndContext>
    );
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;