import { useEffect, useRef, useState } from "react";
import { primaryTheme } from "../../theme";
import searchSpotifySongs from "../../functions/searchSpotifySongs";
import SpotifySearchResultSimplifiedSong from "../../models/SpotifySearchResultSimplifiedSong";
import MergedSongRequest from "../../models/MergedSongRequest";
import { Badge, Button, Tabs, Tab, Paper, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Close, Explicit, Favorite, FavoriteBorder, MusicNote, PlaylistAdd, Search } from "@mui/icons-material";
import "./SongSearch.css";


export interface SongSearchStateObject {
    searchText: string,
    searchResults: SpotifySearchResultSimplifiedSong[]
}

interface FavoritesObject {
    [key: string]: SpotifySearchResultSimplifiedSong
}

interface SongSearchProps {
    auth: any
    createAlert: (severity: string, message: string) => void,
    songSearchState: SongSearchStateObject,
    mergedSongObjects: MergedSongRequest[],
    requestSong: (_song: SpotifySearchResultSimplifiedSong) => void,
    unRequestSong: (_requestSongObj: MergedSongRequest) => Promise<void>,
    favoriteSongs: FavoritesObject,
    addSongToFavorites: (_song: SpotifySearchResultSimplifiedSong) => void,
    removeSongFromFavorites: (_song: SpotifySearchResultSimplifiedSong) => void
    addSongToQueue: (_songQueue: string[], _songId: string) => void,
    songQueue: string[],
    adminView: boolean
}

const SongSearch = ({ auth, createAlert, songSearchState, mergedSongObjects, requestSong, unRequestSong, favoriteSongs, addSongToFavorites, removeSongFromFavorites, addSongToQueue, songQueue, adminView }: SongSearchProps) => {

    const theme = primaryTheme;

    const [searchTextInputValue, setSearchTextInputValue] = useState(songSearchState.searchText);
    const [searchResults, setSearchResults] = useState(songSearchState.searchResults);
    const [searching, setSearching] = useState(false);
    const debounceTimeoutRef = useRef<NodeJS.Timeout|null>(null);
    const [removeSongDialogOpen, setRemoveSongDialogOpen] = useState(false);
    const [removeSongObj, setRemoveSongObj] = useState<MergedSongRequest | undefined>(undefined);

    const searchTextInputRef = useRef(null);

    const tabs = [
        {
            key: "searchSongs",
            displayName: "Search",
            icon: <Search />
        },
        {
            key: "favoriteSongs",
            displayName: "Favorites",
            icon: <Favorite />,
        },
    ];

    const [currentTab, setCurrentTab] = useState(tabs[0].key);

    const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
        setCurrentTab(tabs[newValue].key);
    }


    // Cleanup on component unmount
    useEffect(() => {
        setTimeout(() => {
            focusTextInput();
        }, 300);

        return () => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
        }
    }, []);

    useEffect(() => {
        songSearchState.searchText = searchTextInputValue;
        songSearchState.searchResults = searchResults;
    }, [searchTextInputValue, searchResults]); // eslint-disable-line react-hooks/exhaustive-deps


    // Input change handler
    const handleInputChange = (_value: string) => {
        setSearching(true);
        setSearchTextInputValue(_value);

        // Clear the existing timeout, if any
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        // Set a new timeout
        debounceTimeoutRef.current = setTimeout(() => {
            performSearch(_value);
        }, 500); // Wait for 500ms of inactivity before searching
    };

    const performSearch = async (_searchText: string) => {
        setSearching(true);
        
        if (_searchText === "") {
            setSearchResults([]);
            setSearching(false);
            return;
        } else {
            try {
                const songs = await searchSpotifySongs(_searchText);
                setSearchResults(songs);
                setSearching(false);
            } catch (error) {
                console.error("Error searching for song:", error);
                createAlert("error", "Error searching for song");
                setSearching(false);
            }
        }
    }

    const focusTextInput = () => {
        // @ts-ignore
        searchTextInputRef.current.focus(); // TODO: Figure out what type this is
    };


    return (
        <div className="songSearchDiv">
            <Paper elevation={4}>
                <Tabs
                    indicatorColor="secondary"
                    variant="fullWidth"
                    value={
                        tabs.findIndex((e) => {
                            return e.key === currentTab;
                        })
                    }
                    onChange={handleTabChange}
                    aria-label="tabs"
                >
                    {
                        tabs.map((tab) => {
                            return (<Tab
                                        sx={{ bgcolor: "secondary" }}
                                        icon={tab.icon}
                                        key={tab.key}
                                    />);
                        })
                    }
                </Tabs>
            </Paper>

                <div 
                    style={{ display: currentTab === "searchSongs" ? "flex" : "none" }}
                    className="songSearchDiv"
                >
            <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                    setSearchResults([]);
                                    handleInputChange("");
                                }}
                                edge="end"
                            >
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                style={{
                    "padding": "10px"
                }}
                value={searchTextInputValue}
                onFocus={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.scrollTo(0,0);
                }}
                onChange={(e) => handleInputChange(e.target.value)}
                inputRef={searchTextInputRef}
            />

            <div
                className="searchResults"
                style={
                   searching || (searchResults.length === 0 && !searching) ? (
                        { justifyContent: "center", alignItems: "center" }
                    ) : (
                        {}
                    )
                }
            >
                {
                    searchTextInputValue === "" ? (
                        "Search for a song"
                    ) : (
                        searchResults.length === 0 && !searching ? (
                            <div className="noResults">No results found</div>
                        ) : (
                            null
                        )
                    )
                }

                {
                    searching ? (
                        <CircularProgress color="secondary" />
                    ) : (
                        searchResults.map((song) => {
                            const requestObj = mergedSongObjects.find((e) => e.id === song.id);
                            const songAlreadyRequested = requestObj !== undefined;
                            const userHasRequested = songAlreadyRequested ? requestObj.requests[auth.currentUser?.uid] !== undefined : false;
                            const songHasBeenDeclined = requestObj?.songHasBeenDeclinedRecently ? requestObj.songHasBeenDeclinedRecently : false;

                            const determineSongResultColors = (): string => {
                                if (songHasBeenDeclined) {
                                    return "gray";
                                } else if (requestObj?.songInQueue) {
                                    return theme.palette.secondary.main;
                                } else {
                                    return "";
                                }
                            }

                            return (
                                <Button
                                    className="searchResult"
                                    key={song.id}
                                    variant="text"
                                    style={{
                                        backgroundColor: determineSongResultColors(),
                                        color: mergedSongObjects.map(e => e.id).includes(song.id) ? ( theme.palette.secondary.contrastText ) : ( theme.palette.primary.main )
                                    }}
                                    onClick={() => {
                                        if (songHasBeenDeclined) {
                                            createAlert("error", "The DJ has indicated this song won't be played tonight");
                                        } else {
                                            requestSong(song);
                                        }
                                    }}
                                >
                                    <img
                                        className="searchResultImage"
                                        src={song?.thumbnail}
                                        alt={song?.name}
                                    />

                                    <div className="searchResultTextContent">
                                        <div className="searchResultName">{song?.name}</div>
                                        <div className="searchResultArtists">
                                            {
                                                song?.explicit ? (
                                                    <Explicit
                                                        htmlColor="gray"
                                                        style={{
                                                            fontSize: "14px",
                                                            marginRight: "3px"
                                                        }}
                                                    />
                                                ) : (
                                                    null
                                                )
                                            }
                                            {song?.artists}
                                        </div>
                                    </div>
                                    <div className="searchResultActionArea">
                                        <IconButton
                                            style={{ paddingRight: "20px"}}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                favoriteSongs[song?.id] ? (
                                                    removeSongFromFavorites(song)
                                                ) : (
                                                    addSongToFavorites(song)
                                                )
                                            }}
                                        >
                                            {
                                                favoriteSongs[song?.id] ? (
                                                    <Favorite htmlColor="IndianRed"/>
                                                ) : (
                                                    <FavoriteBorder />
                                                )
                                            }
                                        </IconButton>

                                    </div>

                                    <div 
                                        className="searchResultActionArea"
                                    >
                                    {   
                                        adminView ? (
                                            <Fab
                                                color="primary"
                                                onClick={() => addSongToQueue(songQueue, song?.id)}
                                            >
                                                <Badge
                                                    badgeContent={
                                                        songAlreadyRequested ? (
                                                            Object.keys(requestObj?.requests).length
                                                        ) : (
                                                            ""
                                                        )
                                                    }
                                                    color="primary"
                                                > 
                                                    <PlaylistAdd htmlColor="white" />
                                                </Badge>
                                            </Fab>
                                        ) : (
                                        songAlreadyRequested && !songHasBeenDeclined ? ( 
                                        <Fab
                                            color={ userHasRequested ? "secondary" : "primary" }
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (userHasRequested) {
                                                    setRemoveSongDialogOpen(true);
                                                    setRemoveSongObj(requestObj);
                                                } else {
                                                    requestSong(song);
                                                }
                                            }}
                                            size="small"
                                        >
                                            <Badge
                                                badgeContent={
                                                    songAlreadyRequested ? (
                                                        Object.keys(requestObj?.requests).length
                                                    ) : (
                                                        ""
                                                    )
                                                }
                                                color="primary"
                                            >
                                                <MusicNote htmlColor="white" />
                                            </Badge>
                                        </Fab>
                                        ) : (
                                            <div style={{ width: "40px"}}>

                                            </div>
                                        ))
                                        }
                                    </div>
                                    
                                </Button>
                            );
                        })
                    )
                }
            </div>
            </div>

            <div
                className="searchResults"
                style={ Object.keys(favoriteSongs).length === 0 ? ({
                    display: currentTab === "favoriteSongs" ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center"
                }) : ({ 
                    display: currentTab === "favoriteSongs" ? "flex" : "none", })}
                    
            >
                {
                    Object.keys(favoriteSongs).length === 0 ? (
                        <div style={{ padding: "25px", textAlign: "center" }}> 
                            When you add songs to your favorites, they'll show up here!
                        </div>
                    ) : (
                        Object.entries(favoriteSongs).map(([id, song]) => {
                            const requestObj = mergedSongObjects.find((e) => e.id === song.id);
                            const songAlreadyRequested = requestObj !== undefined;
                            const userHasRequested = songAlreadyRequested ? requestObj.requests[auth.currentUser?.uid] !== undefined : false;
                            const songHasBeenDeclined = requestObj?.songHasBeenDeclinedRecently ? requestObj.songHasBeenDeclinedRecently : false;

                            const determineSongResultColors = (): string => {
                                if (songHasBeenDeclined) {
                                    return "gray";
                                } else if (requestObj?.songInQueue) {
                                    return theme.palette.secondary.main;
                                } else {
                                    return "";
                                }
                            }
                            return (
                                <Button
                                    className="searchResult"
                                    key={song.id}
                                    variant="text"
                                    style={{
                                        backgroundColor: determineSongResultColors(),
                                        color: mergedSongObjects.map(e => e.id).includes(song.id) ? ( theme.palette.secondary.contrastText ) : ( theme.palette.primary.main )
                                    }}
                                    onClick={() => {
                                        if (songHasBeenDeclined) {
                                            createAlert("error", "The DJ has indicated this song won't be played tonight");
                                        } else {
                                            requestSong(song);
                                        }
                                    }}
                                >
                                    <img
                                        className="searchResultImage"
                                        src={song?.thumbnail}
                                        alt={song?.name}
                                    />

                                    <div className="searchResultTextContent">
                                        <div className="searchResultName">{song?.name}</div>
                                        <div className="searchResultArtists">
                                            {
                                                song?.explicit ? (
                                                    <Explicit
                                                        htmlColor="gray"
                                                        style={{
                                                            fontSize: "14px",
                                                            marginRight: "3px"
                                                        }}
                                                    />
                                                ) : (
                                                    null
                                                )
                                            }
                                            {song?.artists}
                                        </div>
                                    </div>
                                    <div className="searchResultActionArea">
                                        <IconButton
                                            style={{ paddingRight: "20px" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                favoriteSongs[song?.id] ? (
                                                    removeSongFromFavorites(song)
                                                ) : (
                                                    addSongToFavorites(song)
                                                )
                                            }}
                                        >
                                            {
                                                favoriteSongs[song?.id] ? (
                                                    <Favorite htmlColor="IndianRed"/>
                                                ) : (
                                                    <FavoriteBorder />
                                                )
                                            }
                                        </IconButton>

                                    </div>

                                    <div 
                                        className="searchResultActionArea"
                                    >
                                    {   songAlreadyRequested && !songHasBeenDeclined ? ( 
                                        <Fab
                                            color={ userHasRequested ? "secondary" : "primary" }
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (userHasRequested) {
                                                    setRemoveSongDialogOpen(true);
                                                    setRemoveSongObj(requestObj);
                                                } else {
                                                    requestSong(song);
                                                }
                                            }}
                                            size="small"
                                        >
                                            <Badge
                                                badgeContent={
                                                    songAlreadyRequested ? (
                                                        Object.keys(requestObj?.requests).length
                                                    ) : (
                                                        ""
                                                    )
                                                }
                                                color="primary"
                                            >
                                                <MusicNote htmlColor="white" />
                                            </Badge>
                                        </Fab>
                                        ) : (
                                            <div style={{ width: "40px"}}>

                                            </div>
                                        )
                                        }
                                    </div>
                                    

                                </Button>

                            );
                        })
                    )
                }
                <div style={{ width: "100%", height: "20vh", clear: "both"}}>

                </div>
            </div>

            <Dialog
                open={removeSongDialogOpen}
                onClose={() => setRemoveSongDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Remove your request?</DialogTitle>

                <DialogContent>
                    <Typography variant="h6">
                        Are you sure you want to unrequest <strong>{removeSongObj?.songData?.name}</strong>?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => {
                            setRemoveSongDialogOpen(false);
                        }}
                        color="primary"
                    >
                        Nevermind
                    </Button>

                    <Button
                        onClick={() => {
                            setRemoveSongDialogOpen(false);
                            if (removeSongObj) {
                                unRequestSong(removeSongObj);
                            }
                        }}
                        color="primary"
                        autoFocus
                    >
                        Remove it!
                    </Button>
                </DialogActions>
            </Dialog>

        </div>


    );
}

export default SongSearch;
