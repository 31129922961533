import { DragOverlay, defaultDropAnimationSideEffects } from "@dnd-kit/core";
import { ReactNode } from "react";

const dropAnimationConfig = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: "0.4"
            }
        }
    })
};

interface SortableOverlayProps {
    children: ReactNode
}

export const SortableOverlay = ({ children }: SortableOverlayProps) => {
    return (
        <DragOverlay
            dropAnimation={dropAnimationConfig}
        >
            { children }
        </DragOverlay>
    );
}