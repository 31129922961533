import axios from "axios";

const getSpotifyAccessToken = async (): Promise<string> => {

    // Check if the token is still valid
    const token: string | undefined | null = localStorage.getItem("spotifyAccessToken");
    const tokenExpiration = Number(localStorage.getItem("spotifyAccessTokenExpiration"));
    const currentTime = new Date().getTime();

    let e;

    if (token && tokenExpiration && currentTime < tokenExpiration) {
        if (token !== null && token !== undefined && token !== "undefined") {
            return token;
        }
    }

    const tokenUrl = "https://us-central1-songpicker-e7101.cloudfunctions.net/api/getAccessToken";

    try {
        const response = await axios.get(tokenUrl);

        const accessToken = response.data.accessToken;

        // Expire the token 55 minutes after it was issued
        const timeout = 55 * 60 * 1000;
        localStorage.setItem("spotifyAccessTokenExpiration", String(new Date().getTime() + timeout));
        console.log("setTokenExpiration");
        localStorage.setItem("spotifyAccessToken", accessToken);
        console.log("setToken");

        return accessToken;
    } catch (error) {
        e = error;
        console.error("Error status:", error);
        localStorage.setItem("spotifyAccessTokenExpiration", "0");
    }

    throw e;
}


export default getSpotifyAccessToken;
