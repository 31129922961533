import { AppBar, Box, Toolbar } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthInterface from "../AuthInterface/AuthInterface";

import "./RootPage.css";

interface RootPageProps {
    auth: any,
    createAlert: (_severity: string, _message: string) => void
}

const RootPage = ({ auth, createAlert }: RootPageProps) => {
    const navigate = useNavigate();

    // Temporary useEffect to send users to /thebarn
    useEffect(() => {
        navigate("/thebarn");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    return (
        <Box className="songPickerAppContent">
            <AppBar position="static">
                <Toolbar>
                    <div style={{ flexGrow: 1 }}></div>
                    <AuthInterface auth={auth} createAlert={createAlert} />
                </Toolbar>
            </AppBar>

            <div className="songPickerContent">

            </div>
        </Box>
    );
}

export default RootPage;

