import axios from "axios";
import getSpotifyAccessToken from "./getSpotifyAccessToken";
import SpotifySearchResultSimplifiedSong from "../models/SpotifySearchResultSimplifiedSong";

const searchSpotifySongs = async (query: string): Promise<SpotifySearchResultSimplifiedSong[]> => {
    try {
        const accessToken = await getSpotifyAccessToken();
        if (accessToken === "") {
            return [];
        }

        const response = await axios.get<SpotifyApi.TrackSearchResponse>("https://api.spotify.com/v1/search", {
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            params: {
                q: query,
                type: "track",
                limit: 5 // You can adjust the limit as needed
            }
        });

        // Transform the API response into a simpler array of metadata
        const songs = response.data.tracks.items.map(track => ({
            id: track.id,
            name: track.name,
            artists: track.artists.map(artist => artist.name).join(", "),
            album: track.album.name,
            uri: track.uri,
            thumbnail: track.album.images[2].url,
            explicit: track.explicit
        }));

        if (songs.length === 0) {
            localStorage.setItem("spotifyAccessTokenExpiration", "0");
        }

        return songs;
    } catch (error) {
        console.error("Error fetching data from Spotify API:", error);
        return([]);
    }
}


export default searchSpotifySongs;
