import firebase from "firebase/compat/app";

const setUserDataFirestore = async (params = {}) => {

    const auth = firebase.auth();
    const user = auth.currentUser;

    try {
        const userRef = firebase.firestore().collection("UserData").doc(user?.uid);
        await userRef.set({
            email: user?.email,
            fbDisplayName: user?.displayName,
            photoURL: user?.photoURL,
            lastSeen: firebase.firestore.FieldValue.serverTimestamp(),
            ...params
        }, { merge: true });
    } catch (error) {
        console.error(error);
    }
}


export default setUserDataFirestore;
