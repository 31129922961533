import { useEffect, useRef, useState, StrictMode } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/analytics";

import { Alert, CircularProgress, Collapse, IconButton } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import SongPicker from "./components/SongPicker/SongPicker";
import NotFound from "./components/NotFound/NotFound";
import RootPage from "./components/RootPage/RootPage";

import createRandomKey from "./functions/createRandomKey";
import setUserDataFirestore from "./functions/setUserDataFirestore";
import getSpotifyAccessToken from "./functions/getSpotifyAccessToken";
import { primaryTheme } from "./theme";
import settings from "./settings.json";

import "./App.css";
import FirestoreUserData from "./models/FirestoreUserData";
import SpotifySearchResultSimplifiedSong from "./models/SpotifySearchResultSimplifiedSong";


firebase.initializeApp(settings.firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();

const App = () => {

    interface FavoritesObject {
        [key: string]: SpotifySearchResultSimplifiedSong
    }

	interface AlertObj {
		severity: string,
		message: string,
		visible: boolean,
		key: string,
		closeTime: number | null
	}

	const alertQueue = useRef<AlertObj[]>([]);

	const [user, setUser] = useState<firebase.User | null>(null);
	const [userMetaData, setUserMetaData] = useState<firebase.User | null>(null);
	const [authLoaded, setAuthLoaded] = useState(false);
	const [refresh, setRefresh] = useState("");
    const [favoriteSongs, setFavoriteSongs] = useState<FavoritesObject>({})

	let userMetaDataListener: any = null;

	// Keeps track of auth state and prevents multiple calls to setUserDataFirestore
	useEffect(() => {

		setInterval(() => {
			if (alertQueue.current[0]?.visible && alertQueue.current[0].closeTime && alertQueue.current[0]?.closeTime < (new Date()).getTime()) {
				closeActiveAlert();
			}
		}, 500);

		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (userMetaDataListener) {
				userMetaDataListener();
				userMetaDataListener = null;
			}

			// Check if authentication has loaded
			if (user !== null) {
				setUser(user);

				// User is signed in, you can access user information here
				try {
					await setUserDataFirestore();
				} catch (error) {
					console.error(error);
				}

				// Listen for changes to user metadata
				userMetaDataListener = firestore.collection("UserData").doc(user.uid).onSnapshot((doc) => {
					if (doc.exists) {
						setUserMetaData(doc.data() as firebase.User);
					} else {
						setUserMetaData(null);
					}
				});

				// Get spotify access token
				getSpotifyAccessToken();
			} else {
				// User is signed out
				setUser(null);
				setUserMetaData(null);
                setFavoriteSongs({});
			}

			setAuthLoaded(true);
		});


		return () => {
            unsubscribe();
        }
	}, []);

    useEffect(() => {

        if (user !== null) {
            // Update the user data in firestore
            const setUserDataFirestoreAsyncWrapper = async () => {
                await setUserDataFirestore();
            };
            setUserDataFirestoreAsyncWrapper();

            // Get user favorites data
            const userFavoritesRef = firestore.collection("UserFavorites").doc(user?.uid);
            const unsubscribeUserFavoritesData = userFavoritesRef.onSnapshot((doc) => {
                if (doc.exists) {
                    setFavoriteSongs(doc.data()?.favoriteSongs as FavoritesObject);
                } else {
                    firestore.collection("UserFavorites").doc(user?.uid).set({
                        UserId: user?.uid,
                        favoriteSongs: {},
                    }, {merge: true});
                }
            });
            return () => {
                unsubscribeUserFavoritesData();
            }
        }
    }, [user]);
	
	
	// Keeps track of alertOpen state and closes alert after 5 seconds but deletes the timeout if alertOpen is set to true again
	const createAlert = (severity: string, message: string) => {
		const alertObj = {
			severity: severity,
			message: message,
			visible: true,
			key: createRandomKey(),
			closeTime: null
		}

		alertQueue.current = [...alertQueue.current, alertObj];
		setRefresh(createRandomKey());

		// If the alertQueue is empty, set the timeout to close the alert
		if (alertQueue.current.length === 1) {
			alertQueue.current[0].closeTime = (new Date()).getTime() + 1000;
		}
	}


	const closeActiveAlert = () => {
		alertQueue.current[0].visible = false;
		setRefresh(createRandomKey());
		setTimeout(() => {
			const tempArray = [...alertQueue.current];
			tempArray.shift();
			alertQueue.current = tempArray;

			// If the alertQueue is not empty, set the timeout to close the alert
			if (alertQueue.current.length > 0) {
				alertQueue.current[0].closeTime = (new Date()).getTime() + 1000;
			}

			setRefresh(createRandomKey());
		}, 500);
	}


	return (
		!authLoaded ? (
			<ThemeProvider theme={primaryTheme}>
				<Router>
					<div className="AppLoading">
						<div className="AppContent">
							<div className="loadingScreen">
								<CircularProgress color="secondary" />
							</div>
						</div>
					</div>
				</Router>
			</ThemeProvider>
		) : (
			<StrictMode>
				<ThemeProvider theme={primaryTheme}>
					<Router>
						<div className="App">
							<Routes>
								<Route
									path="/:urlRoute"
									element={<SongPicker auth={auth} createAlert={createAlert} favoriteSongs={favoriteSongs} setFavoriteSongs={setFavoriteSongs}/>}
								/>

                                <Route
                                    path="/"
                                    element={<RootPage auth={auth} createAlert={createAlert} />}
                                />

								<Route
									path="*"
									element={<NotFound />}
								/>
							</Routes>

							<div className="appAlertHolder">
								<Collapse
									in={alertQueue.current[0]?.visible}
									className="appAlert"
								>
									<Alert
										action={<IconButton
													aria-label="close"
													color="inherit"
													size="small"
													onClick={() => {
														closeActiveAlert();
													}}
												>
													<CloseIcon fontSize="inherit" />
												</IconButton>
										}
										elevation={3}
										// @ts-ignore
										severity={alertQueue.current[0]?.severity}
									>
										{alertQueue.current[0]?.message}
									</Alert>
								</Collapse>
							</div>
						</div>
					</Router>
				</ThemeProvider>
			</StrictMode>
		)
	);
}

export default App;
