import { ReactNode, createContext, useContext, useMemo } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragIndicator } from "@mui/icons-material";

import "./SortableItem.css";
import { UniqueIdentifier } from "@dnd-kit/core";

const SortableItemContext = createContext({
    attributes: {},
    listeners: undefined,
    ref() {}
});

interface SortableItemProps {
    children: ReactNode,
    id: UniqueIdentifier
}

export const SortableItem = ({ children, id }: SortableItemProps) => {
    const {
        attributes,
        isDragging,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition
    } = useSortable({ id });

    const context = useMemo(
        () => ({
            attributes,
            listeners,
            ref: setActivatorNodeRef
        }),
        [attributes, listeners, setActivatorNodeRef]
    );

    const style = {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS.Translate.toString(transform),
        transition
    };

    return (
        // @ts-ignore
        <SortableItemContext.Provider value={context}>
            <li
                className="SortableItem"
                ref={setNodeRef}
                style={style}
            >
                { children }
            </li>
        </SortableItemContext.Provider>
    );
}

export const DragHandle = () => {
    const { attributes, listeners, ref } = useContext(SortableItemContext);

    return (
        <button
            className="DragHandle"
            {...attributes}
            // @ts-ignore
            {...listeners}
            ref={ref}
        >
            <DragIndicator />
        </button>
    );
}